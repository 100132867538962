enum RedirectURLs {
  LOGIN = '/login',
  CODE = '/code',
  CALLBACK = '/callback'
}

export default (isSignedIn: boolean, isVerifiedToken: boolean): RedirectURLs => 
  isSignedIn ?
    isVerifiedToken ?
      RedirectURLs.CALLBACK
    : RedirectURLs.CODE
  : RedirectURLs.LOGIN
