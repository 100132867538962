import React, { useEffect } from 'react'
import useAuth from '@hooks/useAuth'
import { navigate } from 'gatsby'
import getUrlForRedirect from '@utils/getUrlForRedirect'

function Redirect () {
  const { isLoggedIn, isVerifiedToken } = useAuth()

  useEffect(() => {
    navigate(
      getUrlForRedirect(isLoggedIn, isVerifiedToken), {
      replace: true
    })
  }, [
    isLoggedIn,
    isVerifiedToken
  ])

  return null
}

export default Redirect
