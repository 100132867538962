import React, { Fragment } from 'react'
import useAuth from '@hooks/useAuth'
import SEO from '@components/SEO'
import SignupContainer from '@containers/Signup'
import SignupForm from '@HOCs/SignupForm'
import Redirect from '@components/encapsulators/Redirect'

function Signup ({
  location
}) {
  const { accessToken } = useAuth()

  if (Boolean(accessToken)) return <Redirect />

  return (
    <Fragment>
      <SEO title='Crear una cuenta' />

      <SignupForm opts={{
        defaultValues: {
          username: location.state?.usernameValue
        }
      }}>
        <SignupContainer />
      </SignupForm>
    </Fragment>
  )
}

export default Signup
