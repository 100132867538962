import React from 'react'
import useTemplate from '@hooks/useTemplate'

import { Input, Button, Typography } from '@wicadu/arepa/ui'
import styled from '@emotion/styled'

import TextWithCallToAction from '@components/molecules/TextWithCallToAction'

function LoginOrSignupTemplate () {
  const {  translate, loading, onSubmit, goToSwappedUrl } = useTemplate()

  return (
    <Wrapper>
      <Content>
        <div>
          <Typography type='title-4' weight={700}>{translate['es'].TITLE}</Typography>
          <Typography type='description'>{translate['es'].DESCRIPTION}</Typography>
        </div>

        <Input
          name='username'
          htmlType='text'
          placeholder={translate['es'].EMAIL_PLACEHOLDER}
          size='large'
        />
        <Input
          name='password'
          htmlType='password'
          placeholder={translate['es'].PASSWORD_PLACEHOLDER}
          size='large'
        />

        <Button onClick={onSubmit} size='medium'>{translate['es'].BUTTON}</Button>

        <TextWithCallToAction
          text={translate['es'].QUESTION}
          toActionText={translate['es'].QUESTION_LINK}
          toAction={goToSwappedUrl}
        />
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.NEUTRAL.BACKGROUND};
`

const Content = styled.div`
  max-width: 450px;
  display: grid;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  margin: 0 12px;
`

export default LoginOrSignupTemplate
