import React, { useCallback } from 'react'

import { Form } from '@wicadu/arepa/ui'
import { useLazyFetch } from '@wicadu/arepa/hooks'
import { navigate } from 'gatsby'

import { TemplateProvider } from '@hooks/useTemplate'
import LoginOrSignupTemplate from '@components/templates/LoginOrSignup'
import useAuth from '@hooks/useAuth'
import { SignupResponse } from '@ts-types/SignupResponse'
import isBrowser from '@utils/isBrowser'

const { useForm } = Form

const translate = {
  es: {
    TITLE: 'Hola, ingresa tu email y una contraseña',
    DESCRIPTION: 'Para continuar, es necesario que introduzcas tu correo y una contraseña.',
    EMAIL_PLACEHOLDER: 'tu@correo.com',
    PASSWORD_PLACEHOLDER: 'Ingresa tu contraseña',
    BUTTON: 'Registrarme',
    QUESTION: '¿Tienes una cuenta?',
    QUESTION_LINK: 'Iniciar sesión',
  },
}

function SignupContainer() {
  const { logIn } = useAuth()
  const { setError, handleSubmit } = useForm()

  const onCompleted = useCallback(({ accessToken }: SignupResponse): void => {
    logIn(accessToken)
  }, [logIn])

  const onError = useCallback(
    (err: Error): void => {
      if (err.message.match('is registered'))
        return setError('username', {
          type: 'manual',
          message: 'Encontramos una cuenta asociada a este usuario',
        })

      // TODO: Throw default error
    },
    [setError]
  )

  const [signup, { loading }] = useLazyFetch<SignupResponse>(
    `${process.env.OAUTH_SERVER_HOST}/api/auth/sign-up`,
    {
      fetchOptions: {
        method: 'POST',
        headers: isBrowser() && new Headers({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      },
      onCompleted,
      onError,
    }
  )

  const onSubmit = useCallback(
    handleSubmit(({ username, password }) => {
      signup({
        body: {
          username,
          password,
        },
      })
    }),
    [handleSubmit, signup]
  )

  const goToSwappedUrl = useCallback(() => navigate('/login'), [])

  return (
    <TemplateProvider
      value={{
        translate,
        loading,
        onSubmit,
        goToSwappedUrl,
      }}>
      <LoginOrSignupTemplate />
    </TemplateProvider>
  )
}

export default SignupContainer
